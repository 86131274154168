<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills @activate-tab="tabChanged">
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-surname"
                label-cols-lg="2"
                :label="$t('PROFILE.SURNAME')"
                label-for="field-surname-input"
              >
                <b-form-input
                  id="field-surname-input"
                  v-model="dto.fields.surname"
                  trim
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('COMMON.NAME')"
                label-for="field-name-input"
              >
                <b-form-input
                  id="field-name-input"
                  v-model="dto.fields.name"
                  trim
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-patronymic"
                label-cols-lg="2"
                :label="$t('USERS.PATRONYMIC')"
                label-for="field-patronymic-input"
              >
                <b-form-input
                  id="field-patronymic-input"
                  v-model="dto.fields.patronymic"
                  trim
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-authScheduleCronExpression"
                label-cols-lg="2"
                :label="$t('USERS.AUTHORIZATION_SCHEDULE')"
                description="(ex.: 0 0 0 ? * MON *)"
                label-for="field-patronymic-authScheduleCronExpression"
              >
                <b-form-input
                  id="field-authScheduleCronExpression-input"
                  v-model="dto.fields.authScheduleCronExpression"
                  trim
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-blockAfterDt"
                label-cols-lg="2"
                :label="$t('USERS.ACTIVE_UNTIL')"
                label-for="field-patronymic-blockAfterDt"
              >
                <single-date-picker :date.sync="dto.lastRequest">
                </single-date-picker>
              </b-form-group>

              <b-form-group
                id="field-approved"
                label-cols-lg="2"
                :label="$t('USERS.APPROVED')"
                label-for="field-approved-input"
              >
                <b-form-checkbox
                  id="checkbox-approved-input"
                  v-model="dto.approved"
                  name="checkbox-approved"
                  class="pt-2"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                id="field-blocked"
                label-cols-lg="2"
                :label="$t('USERS.BLOCKED')"
                label-for="field-blocked-input"
              >
                <b-form-checkbox
                  id="checkbox-blocked-input"
                  v-model="dto.blocked"
                  name="checkbox-blocked"
                  class="pt-2"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                id="field-password"
                label-cols-lg="2"
                :label="$t('USERS.PASSWORD')"
                :description="$t('USERS.EMPTY_MEANS_NO_CHANGES')"
                label-for="field-password-input"
              >
                <b-form-input
                  id="field-password-input"
                  v-model="dto.password"
                  type="password"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-password-confirm"
                label-cols-lg="2"
                :label="$t('USERS.CONFIRM_PASSWORD')"
                label-for="field-password-confirm-input"
              >
                <b-form-input
                  id="field-password-confirm-input"
                  v-model="confirmPassword"
                  type="password"
                ></b-form-input>
              </b-form-group>
            </b-card-body>
          </b-tab>
          <b-tab :title="$t('USERS.IDENTIFIERS')">
            <b-table
              id="table-identifiers"
              striped
              bordered
              :items="dto.identifiers"
              :fields="identifierFields"
              selectable
              select-mode="single"
              @row-selected="onIdentifierRowSelected"
            >
              <!-- IDENTIFIER TYPE -->
              <template v-slot:cell(identifierTypeId)="data">
                <b-dropdown
                  :text="formatIdentifierName(data)"
                  class="m-md-2"
                  block
                >
                  <b-dropdown-item
                    v-for="identifier in identifiers"
                    :key="identifier.key"
                    :value="identifier.id"
                    @click="onIdentifierChange(data.item, identifier.id)"
                    >{{ identifier.name }}</b-dropdown-item
                  >
                </b-dropdown>
              </template>

              <!-- VALUE -->
              <template v-slot:cell(value)="data">
                <b-form-input v-model="data.item.value" trim></b-form-input>
              </template>

              <!-- VERIFIED -->
              <template v-slot:cell(verified)="data">
                <b-form-checkbox
                  class="pt-2"
                  size="lg"
                  v-model="data.item.verified"
                ></b-form-checkbox>
              </template>

              <!-- PRIMARY -->
              <template v-slot:cell(primary)="data">
                <b-form-checkbox
                  class="pt-2"
                  size="lg"
                  v-model="data.item.primary"
                ></b-form-checkbox>
              </template>
            </b-table>
          </b-tab>

          <b-tab :title="$t('MENU.ROLES')" :key="roleKey">
            <b-table
              id="table-roles"
              striped
              bordered
              :items="roles"
              :fields="roleFields"
            >
              <!-- ACTIVE -->
              <template v-slot:cell(active)="data">
                <b-form-checkbox
                  class="pt-2"
                  size="lg"
                  v-model="data.item.active"
                ></b-form-checkbox>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button variant="success" v-on:click="onSaveClicked">{{
            $t("COMMON.SAVE")
          }}</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
//import Common from "../../common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Application from "../../application";
import SingleDatePicker from "../component/SingleDatePicker";

export default {
  name: "user",
  components: { SingleDatePicker },
  data() {
    return {
      save: {
        resource: "/platform/api/user/save",
        requestType: "POST",
        requestParams: {},
      },
      signUp: {
        resource: "/platform/api/user/sign-up",
        requestType: "POST",
        requestParams: {},
      },
      getRoles: {
        resource: "/platform/api/role",
        requestType: "GET",
        requestParams: {},
      },

      TAB_IDENTIFIERS: 1,
      roleKey: 0, // служит для обновления таблицы с ролями

      dto: {
        id: null,
        authScheduleCronExpression: null,
        fields: {
          name: null,
          surname: null,
          patronymic: null,
        },
        roles: [],
        identifiers: [],
        password: null,
        blocked: false,
        blockAfterDt: null,
        approved: false,
      },
      roles: [],
      roleFields: [
        { label: this.$t("USERS.CODE"), key: "code" },
        { label: this.$t("COMMON.NAME"), key: "name" },
        { label: this.$t("COMMON.ACTIVE"), key: "active" },
      ],

      identifiers: Application.constants.IDENTIFIER_TYPE,

      identifierFields: [
        { label: this.$t("COMMON.TYPE"), key: "identifierTypeId" },
        { label: this.$t("DEVICE.VALUE"), key: "value" },
        { label: this.$t("USERS.VERIFIED"), key: "verified" },
        { label: this.$t("PRODUCTS.PRIMARY"), key: "primary" },
      ],

      selectedIdentifier: null,
      selectedIdentifierIndex: null,

      showOverlay: true,
      confirmPassword: null,

      createNewUserStr: this.$t("USERS.CREATE_NEW_USER"),
      editUserStr: this.$t("USERS.EDIT_USER"),
    };
  },

  mounted() {
    let title = !this.$route.params.id
      ? this.createNewUserStr
      : this.editUserStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.USERS"), route: "/users" },
      { title: title },
    ]);
    this.$store.dispatch(SET_ACTIONS, null);
  },

  created: async function () {
    let result0 = await this.$store
      .dispatch(API_REQUEST, this.getRoles)
      .then((response) => (this.roles = response.data))
      .catch(this.onError);

    if (result0 === undefined) return;

    if (this.$route.params.id != null) {
      await this.load();
    }

    this.showOverlay = false;
  },

  methods: {
    load: function () {
      return this.$store
        .dispatch(API_REQUEST, {
          resource: "/platform/api/user/" + this.$route.params.id,
          requestType: "GET",
        })
        .then((response) => {
          this.dto = response;

          let r = {};

          for (let i = 0; i < this.dto.roles.length; i++) {
            r[this.dto.roles[i]] = true;
          }

          // set active property
          for (let i = 0; i < this.roles.length; i++) {
            let roleId = this.roles[i].id;
            this.roles[i].active = r[roleId] !== undefined ? r[roleId] : false;
          }

          this.roleKey++;
        })
        .catch(this.onError);
    },

    getLang() {
      return localStorage.getItem("language") || "en";
    },

    formatIdentifierName: function (data) {
      for (let key in this.identifiers) {
        const identifier = this.identifiers[key];
        if (identifier.id == data.value) return identifier.name;
      }

      return this.$t("USERS.SELECT_TYPE");
    },

    tabChanged: function (newTabIndex) {
      if (newTabIndex !== this.TAB_IDENTIFIERS) {
        this.$store.dispatch(SET_ACTIONS, null);
        return;
      }

      let that = this;
      this.$store.dispatch(SET_ACTIONS, [
        {
          label: this.$t("ACTIONS.ADD"),
          action: this.onCreateNewIdentifierAction,
          icon: "fas fa-plus",
        },
        {
          label: this.$t("ACTIONS.DELETE"),
          action: this.onDeleteIdentifierAction,
          disabled: function () {
            return that.selectedIdentifier === null || that.showOverlay;
          },
          icon: "fas fa-trash-alt",
        },
      ]);
    },

    onCreateNewIdentifierAction: function () {
      if (this.dto.identifiers === null) this.dto.identifiers = [];

      this.dto.identifiers.push({
        value: null,
        verified: false,
        primary: false,
        identifierTypeId: null,
        identifierTypeName: null,
      });
    },

    onDeleteIdentifierAction: function () {
      for (
        let identifierIndex = 0;
        identifierIndex < this.dto.identifiers.length;
        identifierIndex++
      ) {
        if (
          this.dto.identifiers[identifierIndex].id ===
          this.selectedIdentifier.id
        ) {
          this.dto.identifiers.splice(identifierIndex, 1);
          this.selectedIdentifier = null;
          return;
        }
      }
    },

    onIdentifierRowSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedIdentifier = items[0];
      } else {
        this.selectedIdentifier = null;
      }
    },

    onIdentifierChange: function (identifierDTO, newValue) {
      identifierDTO.identifierTypeId = newValue;
      if (
        newValue === this.identifiers.LOGIN.id &&
        (!identifierDTO.value || identifierDTO.value.length === 0)
      ) {
        identifierDTO.value = (
          this.transliterate(
            this.dto.fields.surname != null ? this.dto.fields.surname : "",
            false
          ) +
          "_" +
          this.transliterate(
            this.dto.fields.name != null && this.dto.fields.name.length > 0
              ? this.dto.fields.name.substr(0, 1)
              : "",
            false
          ) +
          this.transliterate(
            this.dto.fields.patronymic != null &&
              this.dto.fields.patronymic.length > 0
              ? this.dto.fields.patronymic.substring(0, 1)
              : "",
            false
          )
        ).toLowerCase();
      }
    },

    transliterate: function (text, engToRus) {
      let rus =
        "щ   ш  ч  ц  ю  я  ё  ж  ъ  ы  э  а б в г д е з и й к л м н о п р с т у ф х ь".split(
          / +/g
        );
      let eng =
        "shh sh ch cz yu ya yo zh `` y' e` a b v g d e z i j k l m n o p r s t u f x `".split(
          / +/g
        );

      let x;
      for (x = 0; x < rus.length; x++) {
        text = text
          .split(engToRus ? eng[x] : rus[x])
          .join(engToRus ? rus[x] : eng[x]);
        text = text
          .split(engToRus ? eng[x].toUpperCase() : rus[x].toUpperCase())
          .join(engToRus ? rus[x].toUpperCase() : eng[x].toUpperCase());
      }
      return text;
    },

    onSaveClicked: async function () {
      if (
        this.dto.password &&
        this.dto.password.length > 0 &&
        this.confirmPassword !== this.dto.password
      ) {
        this.$bvToast.toast(this.$t("USERS.PASSWORD_MISMATCH"), {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }

      for (let i = 0; i < this.dto.identifiers.length; i++) {
        if (
          this.dto.identifiers[i].identifierTypeId == null ||
          this.dto.identifiers[i].identifierTypeId == ""
        ) {
          this.$bvToast.toast(this.$t("USERS.IDENTIFIER_TYPE_IS_NOT_SET"), {
            title: this.$t("COMMON.ERROR"),
            variant: "danger",
            autoHideDelay: 5000,
          });
          return;
        }
        if (
          this.dto.identifiers[i].value == null ||
          this.dto.identifiers[i].value == ""
        ) {
          this.$bvToast.toast(this.$t("USERS.IDENTIFIER_VALUE_IS_NOT_SET"), {
            title: this.$t("COMMON.ERROR"),
            variant: "danger",
            autoHideDelay: 5000,
          });
          return;
        }
      }

      // if it's new user, we use a different method called signUp
      const request = this.dto.id === null ? this.signUp : this.save;
      const isRegistration = this.dto.id === null;

      this.showOverlay = true;

      // make array with role ids
      this.dto.roles = [];
      for (let i = 0; i < this.roles.length; i++) {
        const role = this.roles[i];

        if (role.active) {
          this.dto.roles.push(role.id);
        }
      }

      request.dto = this.dto;

      let response = await this.$store
        .dispatch(API_REQUEST, request)
        .catch(this.onError);

      if (response == undefined) {
        this.showOverlay = false;
        return;
      }

      if (isRegistration) {
        // registered. Update router address and reload DTO to catch auto-set roles and identifiers
        this.$router.push({ name: "user", params: { id: response.id } });
        this.$route.params.id = response.id;

        await this.load();

        this.$bvToast.toast(this.$t("USERS.USER_SUCCESSFULLY_REGISTERED"), {
          title: this.$t("COMMON.SUCCESS"),
          variant: "success",
          autoHideDelay: 5000,
        });

        this.$store.dispatch(SET_BREADCRUMB, [
          { title: this.$t("MENU.USERS"), route: "/users" },
          { title: this.editUserStr },
        ]);
      } else {
        this.dto.id = response.id;

        this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
          title: this.$t("COMMON.SUCCESS"),
          variant: "success",
          autoHideDelay: 5000,
        });
      }

      this.showOverlay = false;
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}
.form-group {
  margin-bottom: 1rem;
}
</style>
